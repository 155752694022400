import React, { useEffect, useState, useContext } from 'react';
import StatusChannel from './StatusChannel';
import moment from 'moment';
import { Link } from "react-router-dom";
import { useChannel } from '../hook/ConferenceHook';
import { isMobile } from 'react-device-detect';
import ic_doctor from '../ic_doctor.png';
import ic_patient from '../ic_patient.png'
import swal from 'sweetalert';
import { conferences, fb } from '../firebase';
import { addEmitHelper } from 'typescript';
import OnTimeStatus from './OnTimeStatus';
import { UserContext } from '../App';


export default function ChannelRowComponent(props) {
  const user = useContext(UserContext);
  const channelInfo = props.channel;
  const { join, currentChannel } = useChannel();
  const [patientName, setPatientName] = useState('');
  const [doctor, setDoctor] = useState('');
  const [hrId, setHrId] = useState('');
  const [classBtnJoinCall, setClassBtnJoinCall] = useState('');

  const doneCall = (e) => {
    e.preventDefault();
    swal({
      title: e.target.checked ? 'Bạn có chắc chắn là bác sĩ đã khám cho bệnh nhân xong? Bấm "OK" để đánh dấu là cuộc gọi đã kết thúc!' : 'Bạn có chắc chắn cuộc gọi này chưa được hoàn thành không?',
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          var done = !e.target.checked;
          conferences.doc(channelInfo.channel).update({ done: done });
          e.target.checked = done;
        } else { e.target.checked = e.target.checked }
      });
  }


  useEffect(() => {
    if (channelInfo) {
      const patient = channelInfo.users.find((x) => x.role === 'patient');
      if (patient !== undefined)
        setPatientName(patient.name);
      const doctor = channelInfo.users.find((x) => x.role === 'doctor');
      if (doctor !== undefined)
        setDoctor(doctor.name);
      setHrId(channelInfo.hrId);
      if (channelInfo.done) {
        setClassBtnJoinCall("btn-secondary disabled")
      }
      else {
        setClassBtnJoinCall("btn-outline-info")
      }
    }
  }, [channelInfo])

  return (<>

    {
      isMobile ?
        <tr className='text-center' style={(currentChannel && currentChannel.channelId === channelInfo.channel) ? { backgroundColor: 'lightblue' } : null}>
          <td style={{ padding: '3px', verticalAlign: 'middle' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100%' }}>
              <span style={{ backgroundColor: 'lightskyblue', marginBottom: '10px', fontSize: '8pt', paddingTop: '4px', paddingBottom: '4px' }}>{channelInfo.hrId}</span>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <OnTimeStatus onTime={channelInfo.bookedAt.seconds} />
                <span style={{ fontSize: '9pt' }}>{moment(channelInfo.bookedAt.seconds * 1000).format('HH:mm DD/MM')}</span>
              </div>
            </div>
          </td>

          <td className='cell'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}><img src={ic_patient} style={{ width: '18px', marginRight: '10px' }} /><span>{patientName}</span></div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}><img src={ic_doctor} style={{ width: '18px', marginRight: '10px' }} />{doctor}</div>
              <StatusChannel channel={channelInfo} />
            </div>
          </td>
          <td className='cell'>
            {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
            <button
              className={` btn ${classBtnJoinCall} border-3rem`} style={{ padding: '4px', fontSize: '11pt', paddingLeft: '10px', paddingRight: '10px' }}
              onClick={() => join({ patientName: patientName, hrId: hrId, channelId: channelInfo.channel, currentUser:user?user.id:null })}
            >Tham gia</button>
            {/* </div> */}
          </td>
        </tr>
        :
        <tr className='text-center' style={(currentChannel && currentChannel.channelId === channelInfo.channel) ? { backgroundColor: 'lightblue' } : null}>
          {currentChannel ? null : <td className='cell'>{channelInfo.channel}</td>}
          <td className='cell'>{channelInfo.hrId}</td>
          <td className='cell'>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <OnTimeStatus onTime={channelInfo.bookedAt.seconds} />
              <span>{moment(channelInfo.bookedAt.seconds * 1000).format('HH:mm DD/MM')}</span>
            </div>
          </td>
          <td className='cell'>{patientName}</td>
          <td className='cell'>{doctor}</td>
          <td className='cell'><StatusChannel channel={channelInfo} /></td>
          {
            currentChannel ? null :
              <td className='cell'><input type="checkbox" defaultChecked={channelInfo.done} onChange={doneCall} /></td>
          }
          <td className='cell'>
            <button
              className={` btn ${classBtnJoinCall} border-3rem`} style={{ padding: '4px', fontSize: '11pt', paddingLeft: '10px', paddingRight: '10px' }}
              onClick={() => join({ patientName: patientName, hrId: hrId, channelId: channelInfo.channel, currentUser:user?user.id:null })}
              disabled={channelInfo.done}
            >Tham gia</button>
          </td>
        </tr>
    }
  </>
  )

}

const styles = StyleSheet

// export const ENV='dev';
// export const ENV='final';
export const ENV='production';
export const FB_CONFERENCE_COLLECTION = 'conferences'+(ENV==='production'?('-'+ENV):'');
export const USER_COLLECTION = 'users'+(ENV==='production'?('-'+ENV):'');
export const CMS_API = (ENV==='production')?'https://med247.co':'https://med247.me';
// DEV =========================
const FB_CONFIG_DEV = {
    apiKey: "AIzaSyDeRTLyVeyGVt06f473OAIGNB-Zv3Ph8Dc",
    authDomain: "med247videocall.firebaseapp.com",
    databaseURL: "https://med247videocall.firebaseio.com",
    projectId: "med247videocall",
    storageBucket: "med247videocall.appspot.com",
    messagingSenderId: "135198371119",
  };

// FINAL =========================
const FB_CONFIG_PRODUCTION = {
  apiKey: "AIzaSyBF6PkxyOAoTlKcplABblYL34h8XHd5ceQ",
  authDomain: "med247co.firebaseapp.com",
  databaseURL: "https://med247co.firebaseio.com",
  projectId: "med247co",
  storageBucket: "med247co.appspot.com",
  messagingSenderId: "135198371119",
  appId: "1:135198371119:web:095885d988343102ed85da",
  measurementId: "G-689QH4YQZF"
};
export const firebaseConfig = (ENV==='dev')?FB_CONFIG_DEV:FB_CONFIG_PRODUCTION;
// export const USER_COLLECTION = (ENV==='production')?FB_USERS_COLLECTION_PRODUCTION:FB_USERS_COLLECTION_FINAL;
import firebase from "firebase/app";
import "firebase/firestore";
import {FB_CONFERENCE_COLLECTION, firebaseConfig} from './common/env';

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = fb.firestore();
const conferences = db.collection(FB_CONFERENCE_COLLECTION)

export { fb, db, conferences };

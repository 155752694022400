import React, { useContext, useEffect, useState } from "react";
import { AgoraVideoPlayer, createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { Redirect, useParams, } from "react-router-dom";
import axios from 'axios';
import { joinConference, leaveConference, useChannel } from "../hook/ConferenceHook";
import HRInfo from './HRInfo';
import { UserContext } from "../App";
import { CMS_API, ENV } from "../common/env";

const config = {
    mode: "rtc", codec: "vp8",
};

const tempAppId = "d10f05b299634d97bda5a91e4b5f7a2d"; //ENTER DEV APP ID HERE


// the create methods in the wrapper return a hook
// the create method should be called outside the parent component
// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

export default function VideoCall(props) {
    const userContext = useContext(UserContext);
    const { currentChannel, leave } = useChannel();
    const [users, setUsers] = useState([]);
    const [start, setStart] = useState(false);

    const [token, setToken] = useState(null);
    const [appId, setAppId] = useState(null);
    const [authenToken, setAuthenToken] = useState(null);

    // using the hook to get access to the client object
    const client = useClient();
    // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
    const { ready, tracks } = useMicrophoneAndCameraTracks();

    useEffect(() => {
        if (tracks) {
            tracks[0].setEnabled(false);
            tracks[1].setEnabled(false);
        }
    }, [])

    useEffect(() => {
        if (userContext && userContext.token)
            setAuthenToken(userContext.token);
    }, [userContext])

    let initToken = async () => {
        if (start)
            await leaveOldChannel();
        // console.log("CHANNEL ID: ", currentChannel.channelId);
        // setChannelName(currentChannel.channelId);
        getToken(currentChannel.channelId);
        if (ENV === 'dev')
            init('11184', "006d10f05b299634d97bda5a91e4b5f7a2dIADE4P4xEGWEU9h5m5LJlPBw6Eo3qpn6NxZyuAU1hF5OetYlPHch39v0IgBDz45C3IVuYQQAAQDchW5hAgDchW5hAwDchW5hBADchW5h", tempAppId)
    }

    useEffect(() => {
        if (currentChannel && currentChannel.channelId && authenToken) {
            initToken();
        }
        return;
    }, [currentChannel, authenToken])

    useEffect(() => {
        // function to initialise the SDK
            // console.log("init ready - user: ", userContext, ready, tracks, currentChannel, token);

        if (ready && tracks && currentChannel && currentChannel.channelId && token && (appId || (ENV === 'dev'))) {
            console.log("init ready - token: ", token);
            console.log("init ready - appId: ", appId);
            console.log("init ready - channelName: ", currentChannel.channelId);
            init(currentChannel.channelId, token, ENV === 'dev' ? tempAppId : appId);
        } else if (tracks) {
            tracks[0].setEnabled(false);
            tracks[1].setEnabled(false);
        }

    }, [client, ready, tracks, token, appId]);

    let init = async (name, token, appId) => {
        client.on("user-published", async (user, mediaType) => {
            await client.subscribe(user, mediaType);
            console.log("subscribe success");
            if (mediaType === "video") {
                setUsers((prevUsers) => {
                    return [...prevUsers, user];
                });
            }
            if (mediaType === "audio") {
                user.audioTrack?.play();
            }
        });

        client.on("user-unpublished", (user, type) => {
            console.log("unpublished", user, type);
            if (type === "audio") {
                user.audioTrack?.stop();
            }
            if (type === "video") {
                setUsers((prevUsers) => {
                    return prevUsers.filter((User) => User.uid !== user.uid);
                });
            }
        });

        client.on("user-left", (user) => {
            console.log("leaving", user);
            setUsers((prevUsers) => {
                return prevUsers.filter((User) => User.uid !== user.uid);
            });
        });

        await client.join(appId, name, token, userContext ? parseInt(userContext.uid) : 1);
        await joinConference(name, userContext ? userContext.uid : 1);
        if (tracks) {
            console.log("TRACKs: ", tracks);
            await tracks[1].setEnabled(true);
            await tracks[0].setEnabled(true);
            await client.publish([tracks[0], tracks[1]]);
            // tracks[0].setEnabled(false);
        }
        setStart(true);

    };

    const leaveOldChannel = async () => {
        await client.leave();
        client.removeAllListeners();
        // we close the tracks to perform cleanup
        // tracks[0].close();
        // tracks[1].close();
        setStart(false);
        setUsers([]);
        setToken(null);
        if (currentChannel && currentChannel.channelId)
            await leaveConference(currentChannel.channelId, userContext ? userContext.uid : 1);
        // setChannelName(null);
        // await leaveConference("8660");
        // console.log("================ LEAVE =============");
    };

    const getToken = (channel) => {
        const options = {
            url: CMS_API + '/graphql',
            method: 'POST',
            mode: 'no-cors',
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                query: `{agoraGenerateRtcToken(channelName:"${channel}")}`,
                variables: { app: 'web', token: authenToken }
            }
        };
        axios(options)
            .then(response => {
                console.log("========== RESPONSE: ", response);

                var appId = response.data.data.agoraGenerateRtcToken.app_id
                var tokenStr = response.data.data.agoraGenerateRtcToken.token
                setToken(tokenStr);
                setAppId(appId)
            });
    }

    return (

        <div style={{ flex: currentChannel ? 1 : 0, minWidth: currentChannel ? '400px' : 0 }} >
            {
                currentChannel ?
                    <div style={{ display: 'flex', position: 'relative', flexDirection: 'column', height: '100%', width: '100%', background: 'greenyellow', justifyContent: 'center' }}>
                        {ready && tracks && (
                            <Controls tracks={tracks} setStart={setStart} channel={currentChannel.channelId} />
                        )}
                        <div style={{ backgroundColor: 'black', flex: 1 }}>
                            {start && tracks && <Videos users={users} tracks={tracks} />}
                        </div>
                    </div>
                    : <div>Không tìm thấy kênh</div>
            }
        </div >
    );
};

const Videos = (props) => {
    const { users, tracks } = props;

    return (
        <div id="videos">
            {/* AgoraVideoPlayer component takes in the video track to render the stream,
            you can pass in other props that get passed to the rendered div */}
            <AgoraVideoPlayer className='vid' videoTrack={tracks[1]} />
            {users.length > 0 &&
                users.map((user) => {
                    if (user.videoTrack) {
                        return (
                            <AgoraVideoPlayer className='vid' videoTrack={user.videoTrack} key={user.uid} />
                        );
                    } else return null;
                })}
        </div>
    );
};

export const Controls = (props) => {
    const user = useContext(UserContext);
    const client = useClient();
    const { leave } = useChannel();
    const { tracks, setStart } = props;
    const [trackState, setTrackState] = useState({ video: true, audio: true });
    const [redirectComponent, setRedirectionComponent] = useState(null);

    const mute = async (type) => {
        if (type === "audio") {
            await tracks[0].setEnabled(!trackState.audio);
            setTrackState((ps) => {
                return { ...ps, audio: !ps.audio };
            });
        } else if (type === "video") {
            await tracks[1].setEnabled(!trackState.video);
            setTrackState((ps) => {
                return { ...ps, video: !ps.video };
            });
        }
    };

    const leaveChannel = async () => {
        leave();
        await client.leave();
        client.removeAllListeners();
        // we close the tracks to perform cleanup
        // tracks[0].close();
        // tracks[1].close();
        tracks[0].setEnabled(false);
        tracks[1].setEnabled(false);
        setStart(false);
        await leaveConference(props.channel, user ? user.uid : 1);
    };

    return (
        <div className="controls">
            <p className={trackState.audio ? "on" : ""} style={{paddingLeft:'30px', paddingRight:'30px'}}
                onClick={() => mute("audio")}>
                {trackState.audio ? "Tắt tiếng" : "Bật tiếng"}
            </p>
            <p className={trackState.video ? "on" : ""} style={{paddingLeft:'30px', paddingRight:'30px'}}
                onClick={() => mute("video")}>
                {trackState.video ? "Tắt Camera" : "Bật Camera"}
            </p>
            {<p onClick={() => leaveChannel()} style={{paddingLeft:'30px', paddingRight:'30px', backgroundColor:'red'}}>Kết thúc</p>}
            {redirectComponent}
        </div>
    );
};

import React, { useEffect, useState, useContext } from 'react';
import StatusChannel from './StatusChannel';
import moment from 'moment';
import { Link } from "react-router-dom";
import { useChannel } from '../hook/ConferenceHook';
import { isMobile } from 'react-device-detect';
import ic_doctor from '../ic_doctor.png';
import ic_patient from '../ic_patient.png'
import swal from 'sweetalert';
import { conferences, fb } from '../firebase';
import { addEmitHelper } from 'typescript';
import OnTimeStatus from './OnTimeStatus';
import { UserContext } from '../App';
import bg_header from '../bg_header.png';


export default function HealthRecordDetail(props) {
    const user = useContext(UserContext);
    const channelInfo = props.hr;
    const { join, currentChannel } = useChannel();
    const [patientName, setPatientName] = useState('');
    const [doctor, setDoctor] = useState('');
    const [hrId, setHrId] = useState('');

    useEffect(() => {
        if (channelInfo) {
            const patient = channelInfo.users.find((x) => x.role === 'patient');
            if (patient !== undefined)
                setPatientName(patient.name);
            const doctor = channelInfo.users.find((x) => x.role === 'doctor');
            if (doctor !== undefined)
                setDoctor(doctor.name);
            setHrId(channelInfo.hrId);
        }
    }, [channelInfo])

    return (<>
        <div style={{ color: 'white', padding: '14pt', width: '100%', height: 'fit-content', objectFit: 'cover', backgroundImage: `url(${bg_header})`, backgroundSize: 'cover', textAlign: 'center', fontWeight: 'bold' }} >
            <div>
                <span style={{ fontSize: '11pt', color: '#999999', fontStyle: 'italic' }}>Mã phiếu khám: </span>{channelInfo.hrId} &nbsp; &nbsp;-&nbsp; &nbsp;
                <span style={{ fontSize: '11pt', color: '#999999', fontStyle: 'italic' }}>Bác sĩ: </span>{doctor}
            </div>
            <div>
                <button
                    className={` btn btn-outline-info border-3rem`} style={{ fontWeight: 'bold', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '24pt', paddingRight: '24pt', fontSize: '13pt', marginTop: '14px' }}
                    onClick={() => join({ patientName: patientName, hrId: hrId, channelId: channelInfo.channel, currentUser: user ? user.id : null })}
                    disabled={channelInfo.done}
                >Gặp Bác Sĩ Ngay</button>
            </div>
        </div>
    </>
    )

}

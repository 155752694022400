import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useChannel, useGetHRDetail } from "../hook/ConferenceHook";
import { useGetUser } from "../hook/UserHook";
import VideoCall from "./VideoCall";

export default function PatientVideoCallScreen(props) {
  const params = useParams();
  const channel = params.channel;
  const [isShowVideo, setIsShowVideo] = useState(false);
  const { user } = useGetUser();
  const { join } = useChannel();

  const healthRecord = useGetHRDetail(channel);

  useEffect(() => {
    if(healthRecord && user && healthRecord.users) {
        console.log("HEALTH RECORD: ", healthRecord, user)
        healthRecord.users.forEach(record => {
            console.log("User: ", record)
            if(record.role==='patient' && parseInt(record.userId) == parseInt(user.uid)) {
                setIsShowVideo(true)
                join({ patientName: user.name, hrId: healthRecord.hrId, channelId: healthRecord.channel, currentUser:user?user.uid:null })
                console.log("SET SHOW VIDEO: TRUE")
            }
        });
    }
  }, [healthRecord, user]);
  return (
    <div
      className="flex-verti no-padding"
      style={{
        position: "fixed",
        top: '10%',
        left: 0,
        height: "90%",
        width: "100vw",
        zIndex: 9999,
        background: isShowVideo ? "" : "black",
      }}
    >
      {isShowVideo?<VideoCall />:<div style={{color:'white', textAlign:'center', height:'100%', margin:'100px'}}>Bạn không có quyền truy cập kênh này</div>}
    </div>
  );
}

import React, { useContext } from 'react';
import { UserContext } from '../App';
import { VERSION } from "../common/constants";
import { ENV } from '../common/env';

import bg_header from '../bg_header.png';
import ic_doctor from '../ic_doctor.png';
import ic_logo_med247 from '../ic_logo_med247.png';
import { isMobile } from 'react-device-detect';

export default function Header(props) {
    const user = useContext(UserContext);
    const padding = isMobile?'5px':'20px';
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', padding:padding, paddingTop: '3px', paddingBottom: '5px', maxHeight: '80px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {/* <span style={{ fontSize: '9pt' }}>{ENV} - version: {VERSION}</span> */}
                    <div style={{maxWidth:'40vw', width:'200px', maxHeight: '60px', height: '10vh', backgroundImage:`url(${ic_logo_med247})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPositionY:'center' }} />
                </div>
                <div style={{ display: 'flex', width: 'max-content', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '5px' }}>
                        <span style={{ fontWeight: '800', fontSize: '14pt' }}>{isMobile?'':'Xin chào: '} {user ? user.name : ''}</span>
                        <a href='' onClick={props.signOut} style={{ fontSize: '11pt', fontWeight: 'bold' }}>  Đăng xuất </a>
                    </div>
                    <img src={ic_doctor} style={{ maxWidth: '50px', maxHeight: '50px', borderRadius: '50px' }} />
                </div>
            </div>
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import { useCookies } from 'react-cookie';
import { db } from '../firebase'
import firebase from "firebase/app";
import { CMS_API, ENV, USER_COLLECTION } from '../common/env';

function useGetUser() {
    const [userInfo, setUserInfo] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);

    useEffect(()=>{
        if(cookies) {
            getUserInfo();
        }

    }, [cookies])

    const getUserInfo = async () => {
        console.log("COOKIES: ", cookies);
        setUserInfo(cookies.user);
    }

    const saveUser = async (newInfo) => {
      // setCookie('user', JSON.stringify(newInfo));
      setCookie('user', newInfo);
      const userRef = db.collection(USER_COLLECTION).doc(newInfo.uid);
      var userInfo = await userRef.get();
      if (userInfo.exists) {
        userRef.update({ updateAt: firebase.firestore.Timestamp.now() })
      }
      else {
        userRef.set({
          createdAt: firebase.firestore.Timestamp.now(),
          id: parseInt(newInfo.uid),
          role: 'receptionist',
          updateAt: firebase.firestore.Timestamp.now(),
          name:newInfo.name,
        })
      }
    }

    const signOut = () => {
      removeCookie('user');
    }

    return {
      user: userInfo,
      saveUser,
      signOut,
    }
}

export {useGetUser};

import React, { createContext, useEffect, useState, useReducer } from "react";
import './firebase';
import ChannelForm from './components/ChannelForm';
import VideoCall from './components/VideoCall';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from './components/Login';
import { useGetUser } from './hook/UserHook';

import { VERSION } from "./common/constants";
import { ENV } from './common/env';
import HRInfo from './components/HRInfo';
import { useChannel, useGetHRDetail } from './hook/ConferenceHook';
import { ChannelReducer } from './hook/ConferenceHook';
import { CookiesProvider, useCookies } from 'react-cookie';
import Header from './components/Header';
import HealthRecordDetail from './components/HealthRecordDetail';
import PatientVideoCallScreen from './components/PatientVideoCallScreen';

export const ChannelContext = React.createContext('channel');


const UserContext = React.createContext('user');

const App = () => {
  const healthRecordReducer = ChannelReducer();
  const { user, saveUser, signOut } = useGetUser();
  const healthRecord = useGetHRDetail(11184);

  const loginSuccess = async (userInfo) => {
    saveUser(userInfo);
  }

  return (
    <CookiesProvider>
      <UserContext.Provider value={user}>
        {
          // (ENV === 'production') ?
          // user ?
          (user) ?
            <ChannelContext.Provider value={healthRecordReducer}>
              <Router>

                <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Header signOut={signOut} />

                  <Switch>
                    <Route path='/:channel' exact>
                      <PatientVideoCallScreen key='patient'/>
                    </Route>
                    <Route path="/" exact>
                      {
                        healthRecord ? [
                          <HealthRecordDetail key='common-patient' hr={healthRecord} />,
                          <VideoCall key='common-videocall' />
                        ] : null
                      }
                    </Route>
                  </Switch>
                </div>
              </Router>
            </ChannelContext.Provider>
            : <Login loginSuccess={loginSuccess} />
        }
      </UserContext.Provider>
    </CookiesProvider>

  );
};

export { App, UserContext };

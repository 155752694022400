import React, { useState } from 'react';
import axios from 'axios';
import { CMS_API, ENV, USER_COLLECTION } from '../common/env';



export default function Login(props) {
    const loginSuccess = props.loginSuccess
    const [userName, setUserName] = useState(null);
    const [password, setPassWord] = useState(null);

    const login = () => {
        if(ENV==='dev') {
            //Just for testing (Fake data)
            loginSuccess && loginSuccess({
                uid: '1',
                name: 'Supper Man',
                email: 'hoainam141188@gmail.com',
                token: 'Faked token',
            })
            return;
        }
        const options = {
            url: CMS_API + '/graphql',
            method: 'POST',
            dataType: 'json',
            headers: {
                "Access-Control-Allow-Origin": "*",
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                "query": `
                  mutation {
                    login(login: "${userName}", password: "${password}") {
                      user {
                        id
                        name
                        email
                        token
                      }
                      error
                    }
                  }
                `,
                "variables": { "app": "web" }
            },
        };

        axios(options)
            .then(response => {
                console.log(response);
                var data = response.data.data.login.user
                loginSuccess && loginSuccess({
                    uid: data.id,
                    name: data.name,
                    email: data.email,
                    token: data.token,
                })
            });
    }
    return (
        <>
            <main className="login-form">
                <div className="cotainer">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">Đăng nhập tài khoản</div>
                                <div className="card-body">
                                    <div className="form-group row">
                                        <label for="email_address" className="col-md-4 col-form-label text-md-right">Tài khoản</label>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control" onChange={(e) => setUserName(e.target.value)}></input>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="password" className="col-md-4 col-form-label text-md-right">Mật khẩu</label>
                                        <div className="col-md-6">
                                            <input type="password" className="form-control" onChange={(e) => setPassWord(e.target.value)}></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6 offset-md-4">
                                        <button type="submit" className="btn btn-primary" onClick={login}>
                                            Đăng nhập
                                </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
import React, { useContext, useEffect, useState, useReducer } from 'react';
import { conferences, fb } from '../firebase';
import { fullBrowserVersion, browserName } from 'react-device-detect';
import { ChannelContext, UserContext } from '../App';
import firebase from "firebase/app";

function useGetHRDetail(hrId) {
    const [HR, setHR] = useState(null);

    useEffect(() => {
        if (hrId) {
            getHRDetail()
        }
    }, [hrId])

    const getHRDetail = async () => {
        conferences.doc(hrId.toString()).get().then(doc => {
            console.log(doc);
            if (doc.exists) {
                console.log(doc.data());
                setHR({ key: doc.id, ...doc.data() });
            }
        });
    }

    return HR;
}

function useGetConference(count, duration) {
    const [conferenceList, setConferenceList] = useState(null);
    const [changed, setChanged] = useState(null);

    useEffect(() => {
        if (changed) {
            // console.log("CHANGED: ", changed.doc.data());
            var oldChannel = [...conferenceList];
            const indexConf = oldChannel.findIndex(e => e.channel === changed.doc.data().channel);

            if (conferenceList && changed.type === "added" && indexConf == -1) {
                console.log("Added new: ", changed.doc.data());
                oldChannel.unshift({ ...changed.doc.data(), key: changed.doc.id })
            }
            else if (oldChannel && changed.type === "modified" && indexConf != -1) {
                console.log("Modified: ", changed.doc.data());
                oldChannel[indexConf] = { ...changed.doc.data(), key: changed.doc.id }
            }
            else if (oldChannel && changed.type === "removed" && indexConf != -1) {
                oldChannel.splice(oldChannel.findIndex(el => el.channel === changed.doc.data().channel), 1)
            }
            setConferenceList(oldChannel);
        }
    }, [changed])

    useEffect(() => {
        // if (count && conferenceList == null) {
        if (count && duration) {
            getAllConference();
        }
    }, [count, duration])

    const getAllConference = async () => {
        //Get yesterday date
        var date = firebase.firestore.Timestamp.now().toDate();
        date.setDate(date.getDate() - duration);
        date.setHours(0, 0, 0);

        //get current time on server
        const serverTime = firebase.firestore.Timestamp.fromDate(date);
        console.log('Server Time Yesterday: ', serverTime);
        const conRef = conferences.where('bookedAt', '>', serverTime).orderBy('bookedAt', 'desc').limit(count);
        const listConf = await conRef.get();
        var items = [];
        listConf.docs.forEach((channel) => {
            items.push({ key: channel.id, ...channel.data() })
        })
        // console.log(items);
        setConferenceList(items);

        conRef.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                setChanged(change);
            });
            // }
        });
    }

    return conferenceList;
}

const collectData = (channels) => {

    let patient = 0;
    let doctor = 0;
    let no = 0;
    let tkyk = 0;
    let onTime = 0;
    let inTime = 0;
    let callToday = 0;
    let hrOnline = 0;
    let paid = 0;
    let totalHr = 0;
    let oldDate = [];
    let totalAgoraCall = 0;

    if (channels) {
        const currentTime = firebase.firestore.Timestamp.now().seconds;
        channels.forEach(channel => {
            let hasDoctor = false;
            let hasPatient = false;
            if (channel.users) {
                channel.users.forEach(user => {
                    if (user.role === 'doctor')
                        hasDoctor = true;
                    else if (user.role === 'patient')
                        hasPatient = true;
                })
            }

            const bookedDate = channel.bookedAt.toDate();
            const currentDate = firebase.firestore.Timestamp.now().toDate();
            if (bookedDate.getDate() < currentDate.getDate()) {
                //Collect old data from the past
                totalHr++;
                if (oldDate.findIndex(d => d == bookedDate.getDate()) == -1)
                    oldDate.push(bookedDate.getDate());

                //Count old Agora call
                if (hasDoctor && hasPatient)
                    totalAgoraCall++;
            }

            if (bookedDate.getDate() == currentDate.getDate()) {
                hrOnline++;

                if (channel.paid)
                    paid++;

                if (hasDoctor && hasPatient)
                    callToday++;

                switch (channel.status) {
                    case 2: doctor++; break;
                    case 3: patient++; break;
                }
            }

            if (currentTime > channel.bookedAt.seconds && currentTime < channel.bookedAt.seconds + 900)
                inTime++;
            else if (currentTime < channel.bookedAt.seconds && currentTime > channel.bookedAt.seconds - 1800) {
                onTime++;
                if (channel.status == 0) no++;
            }
        });
    }

    // console.log("Patient: ", patient);
    // console.log("doctor: ", doctor);
    // console.log("no: ", no);
    // console.log("tkyk: ", tkyk);
    // console.log("onTime: ", onTime);
    // console.log("inTime: ", inTime);
    // console.log("callToday: ", callToday);
    // console.log("hrOnline: ", hrOnline);


    return {
        patientWait: patient,
        doctorWait: doctor,
        noOne: no,
        tkykJoin: tkyk,
        totalOnTime: onTime,
        totalInTime: inTime,
        totalCallToday: callToday,
        totalHROnline: hrOnline,
        paid: paid,
        hrAcce: (hrOnline - (totalHr / oldDate.length)) / (totalHr / oldDate.length) * 100,
        callAgoraAcce: (callToday - (totalAgoraCall / oldDate.length)) / (totalAgoraCall / oldDate.length) * 100
    }
}

const channelAction = (state, action) => {
    let newSate = { ...state }
    switch (action.type) {
        case 'JOIN':
            newSate.currentChannel = action.data;
            return newSate;
        case 'LEAVE':
            newSate.currentChannel = null;
            return newSate;
        case 'SET_ALL':
            newSate.all = action.data;
            newSate.analysticData = collectData(action.data);
            return newSate;
        default: return state;
    };
}

const useChannel = () => {
    const channelContext = useContext(ChannelContext);
    const [state, dispatch] = channelContext;

    const join = (hr) => {
        // console.log("SET HEALTH RECORD: ", hr);
        if (state == null || hr.channelId !== state.channelId)
            dispatch({ type: 'JOIN', data: hr })
    }
    const leave = (hr) => {
        dispatch({ type: 'LEAVE', data: hr })
    }
    const setAllChannel = (channels) => {
        dispatch({ type: 'SET_ALL', data: channels })
        collectData(channels);
    }

    return {
        currentChannel: state.currentChannel,
        join,
        leave,
        setAllChannel,
        allChannel: state.all,
        analysticData: state.analysticData,
    }
}

const ChannelReducer = () => {
    const oReducer = useReducer(channelAction, {
        patientWait: 0,
        doctorWait: 0,
        noOne: 0,
        tkykJoin: 0,
        totalOnTime: 0,
        totalInTime: 0,
        totalCallToday: 0,
        totalHROnline: 0
    });
    return oReducer;
}

const leaveConference = async (channel, uid) => {
    var conInfo = await conferences.doc(channel.toString()).get();
    if (conInfo.exists) {
        var users = conInfo.data().users;
        if (users && Array.isArray(users) && users.length > 0) {
            const tkykIndex = users.findIndex(i => i.userId === uid);
            if (tkykIndex != -1) {
                users[tkykIndex].updateAt = firebase.firestore.Timestamp.now();
                users[tkykIndex].status = 0;
            }
        }
        await conferences.doc(channel.toString()).update({ users: users, statusReceptionist: 0 });
    }
}

const joinConference = async (channel, uid) => {
    var conInfo = await conferences.doc(channel.toString()).get();
    var tkyk = {
        name: "Nguyễn Mạnh Khương",
        role: 'patient',
        status: 1,
        updateAt: firebase.firestore.Timestamp.now(),
        userId: parseInt(uid),
        device: browserName + '_' + fullBrowserVersion
    }
    if (conInfo.exists) {
        var users = conInfo.data().users;
        if (users && Array.isArray(users) && users.length > 0) {
            const tkykIndex = users.findIndex(i => i.userId === tkyk.userId);
            if (tkykIndex == -1) {
                tkyk.createdAt = firebase.firestore.Timestamp.now();
                users.push(tkyk);
            }
            else {
                tkyk.name = users[tkykIndex].name
                users[tkykIndex] = tkyk;
            }
        } else {
            tkyk.createdAt = firebase.firestore.Timestamp.now();
            users = [tkyk];
        }
        await conferences.doc(channel.toString()).update({ users: users, statusReceptionist: 1 });
    }
}

export { useGetConference, joinConference, leaveConference, useChannel, ChannelReducer, useGetHRDetail }